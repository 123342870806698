<template>
    <div class=container>
        <table class="table" border="0" cellpadding="0" cellspacing="30" width="709">
<tbody><tr>
<td>
  <div align="center">
  <font size="+2"></font>
  </div>

<br>
<br>
<h3>About the Database</h3>

The properties of virtually all real-world materials change with time, causing their BRDFs to be time-varying.
However, none of the existing BRDF models and databases take time variation into consideration; they represent
the appearance of a material at a single time instance. In this work, we address the acquisition, analysis, modeling
and rendering of a wide range of time-varying BRDFs.<br>

<br> 
Our primary contributions in this work are
<br>

<ul>
<li>
 We have developed an acquisition <router-link to="/repository/TVBRDF/documentation">rig</router-link> that is capable of sampling a material’s 
BRDF at multiple time instances, with each time sample acquired within 
36 seconds. We have used this acquisition system to measure the 
BRDFs of a wide range of time-varying phenomena which include the 
drying of various types of paints (watercolor, spray, and oil), 
the drying of wet rough surfaces (cement, plaster, and fabrics), the 
accumulation of dusts (household and joint compound) on surfaces, and 
the melting of materials (chocolate). <br>
<br>

</li><li>
Analytic BRDF functions are fit to these measurements and the model parameters’ variations with time
are analyzed. Each category exhibits interesting and sometimes non-intuitive parameter trends. These parameter
trends are then used to develop analytic time-varying BRDF (TVBRDF) models. The analytic TVBRDF models
enable us to apply effects such as paint drying and dust accumulation to arbitrary surfaces and novel materials.<br>

</li></ul>

<br>
The following image demonstrates some of the effects our data and models capture:<br>
<div style="text-align: center;"><br>
  <img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/about_files/samples.png" border="0" height="748" width="476"></div>
<br>

<br>
The fitted BRDF parameters can be used directly to render the measured materials as
their appearance changes over time. The following video shows spheres rendered with the 
parameters we have fit to the data of six of the samples we have measured:
<br>

<div style="text-align: center; border:1px"><a href="http://www1.cs.columbia.edu/%7Ekks2113/tvBrdfs/img/tsvbrdf.png"></a><br>
  <a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/videos/slides_material_change_comp.avi"><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/about_files/drying_paints.png" border="0" height="317" width="423"></a></div>

<br>

<br>
While the measured data and fitted BRDF parameters can be used by themselves, our
TVBRDF factorization enables us to extend the above effects to novel materials and surfaces.
The following image that shows renderings with both measured blue watercolor on white paper 
data as well as synthesized novel green watercolor on white paper and blue watercolor on red 
paper data demonstrates this:<br>

<div style="text-align: center;"><br>
  <img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/about_files/drying.png" border="0" height="440" width="600"></div>
<br>

Included in the database are the BRDF measurements as well as the parameters of the BRDF models we have fit to the data. 
More detailed information about the database can be found <router-link to="/repository/TVBRDF/measures">here</router-link>.<br>
<br>
The details of our acquisition, modelling and fitting can be found in the following papers:<br>
<br>
<!-- <blockquote> -->
<!--?
print_paperentry_byid($db,"264",false);
print "<br /-->
<!-- print_paperentry_byid($db,"263",false); -->
<!-- ?&gt;
</blockquote> -->

</td>
</tr>

<tr>
  <td>
  <hr style="width: 100%; height: 2px;">
  <router-link to="/repository/TVBRDF">TVBRDF Database Home</router-link><br>
  Contact:&nbsp;<a href="mailto:tvbrdf@cs.columbia.edu">tvbrdf@cs.columbia.edu</a><br>
  Last modified: 06/03/2007
  </td>
</tr>

</tbody></table>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>